import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { t } from "../utils/constant"
import { withTrans } from "../i18n/withTrans"

const NotFoundPage = ({ t: trans }) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className={"container"}>
        <h1>{t({ intl: trans, id: "headerError404" })}</h1>
        <p>{t({ intl: trans, id: "textError404" })}</p>
        <a href="/">Home</a>
      </div>
    </Layout>
  )
}

export default withTrans(NotFoundPage)
